import React from "react";
import styles from "../css/ContactCard.module.css";
import { BsLinkedin, BsTelephoneFill } from "react-icons/bs";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const ContactCard = ({ member }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.profileDiv}>
        <img src={member.Pic.url} alt="Head" className={styles.organizerImage} />
      </div>
      <div className={styles.infoDiv}>
        <div className={styles.nameDiv}>
          <span className={styles.name}>{member.Name}</span>
        </div>
        <div className={styles.socialDiv}>
          <a target="_blank" href={`tel:${member.Phone}`}>
            <BsTelephoneFill className={styles.socialIconphone} />
          </a>
          <a href={`mailto:${member.Email}`} className={styles.sociallink}>
            <HiOutlineMail className={styles.socialIcon} />
          </a>
          <a href={member.Instagram} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <FaInstagramSquare className={styles.socialIcon} />
          </a>
          <a href={member.Facebook} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <FaFacebookSquare className={styles.socialIcon} />
          </a>
          <a href={member.LinkedIn} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <BsLinkedin className={styles.socialIcon} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default ContactCard;