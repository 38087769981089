import { BrowserRouter, Routes, Route } from "react-router-dom";
import styles from "../css/App.module.css";
import {
  LandingPage,
  Home,
  Sponsors,
  Media,
  CoreTeam,
  Queries,
  HallOfFame
} from "../pages";
import ChatAssistant from "../components/chatAssistant";
import Navbar from "./Navbar";
import Attractions from "../pages/StarAttractions";
import Brochure from "../pages/Brochure";
import Loader from "./Loader";
import Gallery from "../pages/Gallery";
const App = () => {
  return (
    <div className={styles.outer_container}>
      <BrowserRouter>
        <Navbar />
          {/* <ChatAssistant /> */}
        <Routes>
          <Route exact path="/" element={<><LandingPage />
          <Home />
          </>}>
          </Route>
          {/* <Route exact path="/sponsors" element={<Sponsors />}></Route> */}
          <Route exact path="/media" element={<Media />}></Route>
          <Route exact path="/coreTeam" element={<CoreTeam />}></Route>
          <Route exact path="/queries" element={<Queries />}></Route>
          <Route exact path="/brochure" element={<Brochure />}></Route>
          <Route exact path="/attractions" element={<Attractions />}></Route>
          <Route exact path="/gallery" element={<Gallery />}></Route>
          <Route exact path="/hof" element={<HallOfFame />}></Route>
          {/* <Route exact path="/schedule" element={<Loader />}></Route> */}
          {/* <Route exact path="/merchandise" element={<Media />}></Route> */}
          <Route
            exact
            path="*"
            element={<h1>Error 404: Page Not Found</h1>}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;