import React from "react";
import styles from "../css/organizerCard.module.css"; // Assuming your CSS is in this file
import { FaLinkedin, FaInstagram, FaFacebook, FaEnvelope } from "react-icons/fa";
import Call_icon from "../assets/contact/Call.svg";
import Ring_icon from "../assets/contact/Call_circle.svg";

function OrganizerCard({ organizer }) {
  const { Pic, Name, Contact, Email, Facebook, LinkedIn, Instagram } = organizer;

  return (
    <div className={styles.card}>
      <img src={Pic.content.url} alt={Name.content} className={styles.img} />
      <div className={styles.textBox}>
        <div className={styles.organizerName}>
          <span>{Name.content}</span>
        </div>
        <div className={styles.organizersociallinks}>
          <a href={`mailto:${Email.content}`} className={styles.sociallink}>
            <FaEnvelope className={styles.socialIcon} />
          </a>
          <a href={Instagram.content} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <FaInstagram className={styles.socialIcon} />
          </a>
          <a href={Facebook.content} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <FaFacebook className={styles.socialIcon} />
          </a>
          <a href={LinkedIn.content} className={styles.sociallink} target="_blank" rel="noopener noreferrer">
            <FaLinkedin className={styles.socialIcon} />
          </a>
        </div>
        <div className={styles.contactContainer}>
          <div className={styles.iconWrapper}>
            <img src={Ring_icon} alt="Ring Icon" className={styles.ringImage} />
            <img src={Call_icon} alt="Call Icon" className={styles.callIcon} />
          </div>
          <h3>{`+91-${Contact.content}`}</h3>
        </div>
      </div>
    </div>
  );
}

export default OrganizerCard;
